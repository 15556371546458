// EXEMPT

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { appURLs, hardCodeURLs } from "@/app/constants.mjs";
import DownloadIcon from "@/inline-assets/download-icon.svg";
import {
  BrandColors,
  BrandFonts,
  DownloadButton,
  HeadingSection,
  LogoStyle,
  LogoStylesContainer,
  MainSections,
  PageContainer,
  Section,
  TabButton,
  TabList,
  TabPanel,
  TabPanels,
} from "@/marketing/BrandAssets.style.jsx";
import MarketingFooter from "@/marketing/MarketingFooter.jsx";
import MarketingHeader from "@/marketing/MarketingHeader.jsx";
import { classNames } from "@/util/class-names.mjs";

const LOGO_TABS: Array<{ value: string; label: Translation }> = [
  {
    value: "blitz",
    label: ["common:blitzLogo", "Blitz Logo"],
  },
  {
    value: "premium",
    label: ["common:subscriberLogo", "Premium Logo"],
  },
];

type LogoTabId = (typeof LOGO_TABS)[number]["value"];

const LOGO_TYPES: Record<
  LogoTabId,
  Array<{
    title: Translation;
    background: string;
    border?: string;
    logo: {
      svg: string;
      webp: string;
    };
  }>
> = {
  blitz: [
    {
      title: ["common:logotypes.logoLight", "Full color logo (white)"],
      background: "var(--shade9)",
      border: "1px solid var(--shade2-15)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-light.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-light.webp`,
      },
    },
    {
      title: ["common:logotypes.logoDark", "Full color logo (dark)"],
      background: "var(--shade0)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-dark.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-dark.webp`,
      },
    },
    {
      title: ["common:logotypes.logoMonochromeDark", "Monochrome logo (dark)"],
      background: "var(--shade0)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-monochrome-dark.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-monochrome-dark.webp`,
      },
    },
    {
      title: [
        "common:logotypes.logoMonochromeLight",
        "Monochrome logo (white)",
      ],
      background: "linear-gradient(316.33deg, #CE0F50 26.51%, #FE112D 74.7%)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-monochrome-light.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-monochrome-light.webp`,
      },
    },
  ],
  "blitz-pro": [
    {
      title: ["common:logotypes.logoLight", "Full color logo (white)"],
      background: "var(--shade9)",
      border: "1px solid var(--shade2-15)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-pro-light.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-pro-light.webp`,
      },
    },
    {
      title: [
        "common:logotypes.proStackedLogoLight",
        "Stacked full color logo (white)",
      ],
      background: "var(--shade9)",
      border: "1px solid var(--shade2-15)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-pro-stacked-light.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-pro-stacked-light.webp`,
      },
    },
    {
      title: [
        "common:logotypes.proMonochromeLogoLight",
        "Monochrome logo (white)",
      ],
      background: "linear-gradient(45deg, #92713C 0%, #DBB063 100%)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-pro-monochrome-light.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-pro-monochrome-light.webp`,
      },
    },
    {
      title: [
        "common:logotypes.proStackedMonochromeLogoLight",
        "Stacked monochrome logo (white)",
      ],
      background: "linear-gradient(45deg, #92713C 0%, #DBB063 100%)",
      logo: {
        svg: `${appURLs.CDN}/common/img/brand/logo-pro-stacked-monochrome-light.svg`,
        webp: `${appURLs.CDN}/common/img/brand/logo-pro-stacked-monochrome-light.webp`,
      },
    },
  ],
};

const BRAND_COLORS: Array<{
  background: string;
  border?: string;
  title: Translation;
  color: string;
}> = [
  {
    background: "var(--primary)",
    title: ["common:brandAssets.colors.blitzPrimary", "Blitz Primary"],
    color: "#FF112D",
  },
  {
    background: "#CF1050",
    title: ["common:brandAssets.colors.blitzSecondary", "Blitz Secondary"],
    color: "#CF1050",
  },
  {
    background: "var(--cta-gradient)",
    title: ["common:brandAssets.colors.blitzGradient", "Blitz Gradient"],
    color: "#FF112D - #CF1050",
  },
  {
    background: "var(--subscriber-solid)",
    title: ["common:brandAssets.colors.subscriberGold", "Subscriber Gold"],
    color: "#C49C57",
  },
  {
    background: "var(--subscriber-gradient)",
    title: [
      "common:brandAssets.colors.subscriberGradient",
      "Subscriber Gradient",
    ],
    color: "#92713C - #DBB063",
  },
  {
    background: "var(--shade0)",
    title: ["common:brandAssets.colors.grayScaleShade0", "Grayscale Shade 0"],
    color: "#E3E5EA",
  },
  {
    background: "var(--shade1)",
    title: ["common:brandAssets.colors.grayScaleShade1", "Grayscale Shade 1"],
    color: "#999CA2",
  },
  {
    background: "var(--shade2)",
    title: ["common:brandAssets.colors.grayScaleShade2", "Grayscale Shade 2"],
    color: "#7F838B",
  },
  {
    background: "var(--shade3)",
    title: ["common:brandAssets.colors.grayScaleShade3", "Grayscale Shade 3"],
    color: "#595D66",
  },
  {
    background: "var(--shade4)",
    title: ["common:brandAssets.colors.grayScaleShade4", "Grayscale Shade 4"],
    color: "#474B53",
  },
  {
    background: "var(--shade5)",
    title: ["common:brandAssets.colors.grayScaleShade5", "Grayscale Shade 5"],
    color: "#353840",
  },
  {
    background: "var(--shade6)",
    title: ["common:brandAssets.colors.grayScaleShade6", "Grayscale Shade 6"],
    color: "#E3E5EA",
  },
  {
    background: "var(--shade7)",
    title: ["common:brandAssets.colors.grayScaleShade7", "Grayscale Shade 7"],
    color: "#999CA2",
  },
  {
    background: "var(--shade8)",
    title: ["common:brandAssets.colors.grayScaleShade8", "Grayscale Shade 8"],
    color: "#7F838B",
  },
  {
    background: "var(--shade9)",
    title: ["common:brandAssets.colors.grayScaleShade9", "Grayscale Shade 9"],
    color: "#595D66",
  },
  {
    background: "var(--shade10)",
    border: "1px solid var(--shade2-15)",
    title: ["common:brandAssets.colors.grayScaleShade10", "Grayscale Shade 10"],
    color: "#474B53",
  },
];

const FONTS: Array<{
  name: string;
  fontFamily: string;
  title: Translation;
  download: string;
  types: Array<{
    type: string;
    fontFamily?: string;
    fontWeight: number;
  }>;
}> = [
  {
    name: "Inter",
    fontFamily: "Inter",
    title: ["common:fonts.Inter", "Inter Font Family"],
    download: hardCodeURLs.DOWNLOAD_INTER_FONT,
    types: [
      {
        type: "Light",
        fontWeight: 300,
      },
      {
        type: "Regular",
        fontWeight: 400,
      },
      {
        type: "Medium",
        fontWeight: 500,
      },
      {
        type: "Semi Bold",
        fontWeight: 600,
      },
      {
        type: "Bold",
        fontWeight: 700,
      },
      {
        type: "Black",
        fontWeight: 900,
      },
    ],
  },
  // {
  //   name: "Qtype \n Square",
  //   fontFamily: "QTypeBold",
  //   title: ["common:fonts.QtypeSquare", "QtypeSquare Font Family"],
  //   download: `${appURLs.CDN_PLAIN}/blitz/ui/fonts/QTYPESQUARE-Fonts.zip`,
  //   types: [
  //     {
  //       type: "Light",
  //       fontFamily: "QTypeLight",
  //       fontWeight: 200,
  //     },
  //     {
  //       type: "Regular",
  //       fontFamily: "QTypeLight",
  //       fontWeight: 300,
  //     },
  //     {
  //       type: "Medium",
  //       fontFamily: "QTypeLight",
  //       fontWeight: 400,
  //     },
  //     {
  //       type: "Semi Bold",
  //       fontWeight: 500,
  //     },
  //     {
  //       type: "Bold",
  //       fontWeight: 700,
  //     },
  //   ],
  // },
];

export default function BrandAssets() {
  const [activeLogoTab, setActiveLogoTab] = useState<LogoTabId>("blitz");

  const onChangeActiveLogoTab = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setActiveLogoTab(e.target.value as LogoTabId);
    },
    [setActiveLogoTab],
  );

  const { t } = useTranslation();

  return (
    <PageContainer>
      <MarketingHeader />

      <HeadingSection>
        <div>
          <h1>{t("home:brandAssets.title", "Blitz Brand Assets")}</h1>
          <p>
            {t(
              "home:brandAssets.description",
              "Our sophisticated visual language energizes and empowers gamers of all skill levels.",
            )}
          </p>
        </div>
      </HeadingSection>

      <MainSections>
        <Section>
          <h2>{t("home:brandAssets.section1.caption", "Logotypes")}</h2>

          <TabList role="tablist" aria-label="Features Tabs">
            {LOGO_TABS.map(({ value, label }, index) => (
              <TabButton key={value} role="tab">
                <input
                  role="button"
                  id={`tab-${value}`}
                  aria-controls={`tab-panel-${value}`}
                  className="visually-hidden"
                  type="radio"
                  name="game"
                  value={value}
                  defaultChecked={index === 0}
                  onChange={onChangeActiveLogoTab}
                />

                <div>{t(...label)}</div>
              </TabButton>
            ))}

            <div>
              <DownloadButton
                download={t(
                  "common:brandAssets.download.allLogoTypes",
                  "All_logo_types.zip",
                )}
                href={`${appURLs.CDN_PLAIN}/blitz/ui/zip/logo_assets.zip`}
              >
                <DownloadIcon />
                {t("common:downloadAll", "Download All")}
              </DownloadButton>
            </div>
          </TabList>

          <TabPanels>
            {Object.entries(LOGO_TYPES).map(([id, items]) => (
              <TabPanel
                key={id}
                id={`tab-panel-${id}`}
                aria-hidden={!(id === activeLogoTab)}
              >
                <LogoStylesContainer>
                  {items.map(({ title, background, border, logo }, index) => (
                    <LogoStyle key={`${id}-${index}`}>
                      <div
                        {...classNames(css`
                          border: ${border ?? "none"};
                          background: ${background ?? "transparent"};
                        `)}
                      >
                        <img src={logo.webp} role="presentation" />
                      </div>

                      <figcaption>
                        <span>{t(...title)}</span>
                        <div>
                          <DownloadButton
                            download={`${t(...title)}.png`}
                            href={logo.webp}
                          >
                            <DownloadIcon />
                            {t("common:png", "PNG")}
                          </DownloadButton>
                          <DownloadButton
                            download={`${t(...title)}.svg`}
                            href={logo.svg}
                          >
                            <DownloadIcon />
                            {t("common:svg", "SVG")}
                          </DownloadButton>
                        </div>
                      </figcaption>
                    </LogoStyle>
                  ))}
                </LogoStylesContainer>
              </TabPanel>
            ))}
          </TabPanels>
        </Section>

        <Section>
          <h2>
            {t(
              "home:brandAssets.section2.colorsAndGradients",
              "Colors & Gradients",
            )}
          </h2>

          <BrandColors>
            {BRAND_COLORS.map((v, i) => (
              <figure key={`color-${i}`}>
                <div
                  style={{
                    background: v.background,
                    border: v.border || "none",
                  }}
                />

                <figcaption>
                  {t(...v.title)}

                  <span>{v.color}</span>
                </figcaption>
              </figure>
            ))}
          </BrandColors>
        </Section>

        <Section>
          <h2>{t("home:brandAssets.section3.caption", "Fonts")}</h2>

          <BrandFonts>
            {FONTS.map((font, i) => (
              <figure key={`font-${i}`}>
                <div>
                  <h3
                    style={{
                      fontFamily: font.fontFamily,
                    }}
                  >
                    {font.name}
                  </h3>

                  <ul>
                    {font.types.map(({ type, fontFamily, fontWeight }) => (
                      <li
                        key={type}
                        style={{
                          fontFamily: fontFamily ?? font.fontFamily,
                          fontWeight,
                        }}
                      >
                        {type}
                      </li>
                    ))}
                  </ul>
                </div>

                <figcaption>
                  <span>{t(...font.title)}</span>
                  <DownloadButton href={font.download} download={font.title}>
                    <DownloadIcon />
                    {t("common:download", "Download")}
                  </DownloadButton>
                </figcaption>
              </figure>
            ))}
          </BrandFonts>
        </Section>
      </MainSections>

      <MarketingFooter expanded />
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["home:meta.brandAssets.title", "Blitz.gg - Brand Assets"],
    description: [
      "home:meta.brandAssets.description",
      "View and download Blitz's brand assets, such as logos, colors, and fonts. Blitz is a digital service providing features that help gamers improve.",
    ],
  };
}
