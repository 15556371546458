import { styled } from "goober";
import {
  desktop,
  mobile,
  mobileMedium,
  tabletLarge,
} from "clutch/src/Style/style.mjs";

export const PageContainer = styled("div")`
  --section-gap: var(--sp-30);
  --content-max-width: min(1200px, 100vw);

  width: 100%;
  margin: 0 auto;

  footer {
    margin-top: var(--sp-24);
  }

  ${mobile} {
    --section-gap: var(--sp-16);
  }
`;

export const Section = styled("section")`
  width: 100%;
  margin-inline: auto;

  ${desktop} {
    padding-inline: var(--sp-12);
  }

  ${mobile} {
    padding-inline: var(--sp-6);
  }
`;

export const HeadingSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${"" /* due to design */}
  min-height: 768px;

  color: var(--white);

  ${mobile} {
    min-height: 568px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: var(--content-max-width);
    margin: var(--sp-24) auto;
    padding: 0px 5%;

    text-align: center;

    h1 {
      font-weight: 500;
      font-size: var(--sp-3);
      margin-bottom: var(--sp-6);
      text-transform: uppercase;

      ${mobileMedium} {
        margin-bottom: var(--sp-4);
      }
    }

    p {
      font-size: var(--sp-15);
      font-weight: normal;
      line-height: var(--sp-19);
      letter-spacing: -0.5px;

      ${mobile} {
        font-size: var(--sp-12);
        line-height: var(--sp-16);
      }

      ${mobileMedium} {
        font-size: var(--sp-7);
        font-weight: 700;
        line-height: var(--sp-10);
        letter-spacing: -0.009em;
      }
    }
  }
`;

export const MainSections = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--section-gap);

  margin: var(--section-gap) 0;

  overflow: hidden;

  section {
    max-width: var(--content-max-width);

    h2 {
      font-weight: 700;
      font-size: var(--sp-8);
      line-height: var(--sp-11);
      letter-spacing: -0.009em;
    }
  }
`;

export const DownloadButton = styled("a")`
  display: flex;
  align-items: center;
  gap: var(--sp-1);

  padding: var(--sp-0_5) var(--sp-2_5) var(--sp-0_5) var(--sp-1);

  background: var(--shade7);
  border-radius: var(--br);

  color: var(--shade1) !important;

  font-size: var(--sp-3_5);
  font-weight: 600;
  line-height: var(--sp-5);
  letter-spacing: -0.009em;

  transition: var(--transition);

  cursor: pointer;

  svg {
    width: var(--sp-7);
    height: var(--sp-7);
  }
`;

export const TabList = styled("div")`
  display: flex;
  gap: var(--sp-6);

  margin: var(--sp-4) 0 var(--sp-12);

  border-bottom: 1px solid var(--shade0-15);

  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-grow: 1;

    ${mobileMedium} {
      display: none;
    }
  }
`;

export const TabButton = styled("label")`
  position: relative;

  display: flex;
  gap: var(--sp-12);

  cursor: pointer;

  input {
    width: 100% !important;
    height: 100% !important;
  }

  & > div:hover,
  input:focus + div,
  input:checked + div {
    color: var(--shade2);
  }

  input:checked + div {
    color: var(--shade0);
  }

  input:checked + div::after {
    content: "";

    position: absolute;
    bottom: calc(var(--sp-px) * -1);
    left: 50%;

    width: var(--sp-6);
    height: calc(var(--sp-0_5) + var(--sp-px));

    background: var(--primary);
    border-radius: calc(var(--sp-0_5) + var(--sp-px))
      calc(var(--sp-0_5) + var(--sp-px)) 0 0;

    transform: translateX(-50%);
  }

  & > div {
    padding: var(--sp-2) var(--sp-1) var(--sp-4);

    color: var(--shade4);

    font-size: var(--sp-4);
    text-align: center;

    white-space: nowrap;

    transition: color 0.2s;

    span {
      color: hsl(222deg 7% 37%);

      font-weight: 600;
      font-size: var(--sp-3_5e);
      letter-spacing: -0.009em;

      transition: color 0.2s;
    }
  }
`;

export const TabPanels = styled("div")`
  display: grid;

  & > div {
    grid-area: 1 / 1;
  }
`;

export const TabPanel = styled("div")`
  width: 100%;

  transition: all 0.2s;

  &[aria-hidden="true"] {
    opacity: 0;
  }
`;

export const LogoStylesContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--sp-12) var(--sp-6);

  ${mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LogoStyle = styled("figure")`
  margin: 0;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 240px;
    margin-bottom: var(--sp-3);

    border-radius: var(--sp-2);

    ${mobileMedium} {
      min-height: 140px;

      img {
        transform: scale(0.5);
      }
    }
  }

  figcaption {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${mobileMedium} {
      flex-direction: column;
      gap: var(--sp-3);
    }

    span {
      color: var(--white);

      font-size: var(--sp-3);
      font-weight: 700;
      line-height: var(--sp-5);
      letter-spacing: -0.009em;
    }

    div {
      display: flex;
      gap: var(--sp-3);
    }
  }
`;

export const BrandColors = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--sp-6);

  margin-top: var(--sp-12);

  ${desktop} {
    grid-template-columns: repeat(5, 1fr);
    gap: var(--sp-6) var(--sp-5);
  }

  ${tabletLarge} {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--sp-6) var(--sp-4);
  }

  ${mobile} {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--sp-6) var(--sp-3);
  }

  figure {
    margin: 0;

    div {
      height: var(--sp-12);
      border-radius: var(--sp-2);
      margin-bottom: var(--sp-3);
    }

    figcaption {
      color: var(--shade1);

      font-weight: 700;
      font-size: var(--sp-3_5);
      line-height: var(--sp-6);

      span {
        display: block;

        color: var(--shade3);

        font-size: 11px;
        font-weight: 600;
        line-height: var(--sp-5);
        letter-spacing: -0.009em;
      }
    }
  }
`;

export const BrandFonts = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--sp-6);

  margin-top: var(--sp-12);

  ${tabletLarge} {
    grid-template-columns: repeat(1, 1fr);
    gap: var(--sp-12);
  }

  figure {
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);

    height: 100%;
    margin: 0;

    & > div {
      display: flex;
      justify-content: space-between;

      height: 100%;
      padding: var(--sp-12);

      color: var(--white);

      border: 1px solid var(--shade2-15);
      border-radius: var(--sp-2);

      ${mobileMedium} {
        padding: var(--sp-6);
      }

      h3 {
        font-weight: 700;
        font-size: var(--sp-8);
        line-height: var(--sp-11);
        letter-spacing: -0.009em;

        white-space: pre-line;

        ${mobileMedium} {
          font-weight: 600;
          font-size: var(--sp-4_5);
          line-height: var(--sp-7);
          letter-spacing: -0.009em;
        }
      }

      ul {
        font-size: var(--sp-4);
        line-height: var(--sp-6);
        letter-spacing: -0.009em;
        text-align: right;
      }
    }

    figcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: var(--white);

        font-size: var(--sp-3);
        font-weight: 700;
        line-height: var(--sp-5);
        letter-spacing: -0.009em;

        ${mobileMedium} {
          font-size: var(--sp-2);
        }
      }
    }
  }
`;
